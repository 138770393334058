<template>
  <div class="admin-login">
    <h4 class="mb-4">
      LOGIN
    </h4>
    <form
      ref="loginForm"
      class="login-form"
      @submit.prevent="onLogin"
    >
      <base-input
        ref="usernameInput"
        v-model="username"
        :error-msg="error.username ? 'Please enter username' : ''"
        input-id="adminLoginFormBenutzername"
        placeholder="Benutzername"
        type="text"
        always-active
        @on-enter="onLogin"
        @input="error.username = false"
      />
      <div class="password-field">
        <base-input
          ref="passwordInput"
          v-model="password"
          :error-msg="error.password ? 'Please enter password' : ''"
          input-id="adminLoginFormPasswort"
          placeholder="Passwort"
          type="password"
          always-active
          @on-enter="onLogin"
          @input="error.password = false"
        />
        <span
          class="show-password"
          @click.prevent="showPasswordHandler"
        >
          <icon-eye :slash="!isShowPassword" />
        </span>
      </div>
      <b-btn
        ref="submitbtn"
        type="submit"
        variant="tab-orange"
        class="text-white"
      >
        Login
      </b-btn>
    </form>
  </div>
</template>

<style lang="scss">
@import "@/styles/pages/admin-login.scss";
</style>

<script>
import IconEye from '@/components/icons/IconEye';
import { AdminInformation } from '@/services';

export default {
  components: {
    IconEye,
  },
  data() {
    return {
      username: '',
      password: '',
      error: {
        username: false,
        password: false,
      },
      isShowPassword: false,
    };
  },
  computed: {
    isFormError() {
      return this.error.username || this.error.password;
    },
  },
  methods: {
    formValidation() {
      this.error.username = this.username === '';
      this.error.password = this.password === '';
    },
    async onLogin() {
      this.formValidation();

      if (!this.isFormError) {
        try {
          const response = await AdminInformation.login({
            username: this.username,
            password: this.password,
          });

          if (response.status === 200) {
            sessionStorage.setItem('admin', response.data.accessToken);
            setTimeout(() => {
              window.location.href = '/admin/booking';
            }, 1500);
          } else {
            this.$bvModal.msgBoxOk('Etwas stimmt nicht. Bitte versuche es erneut.', {
              title: 'Login Fehler',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            });
          }
        } catch (err) {
          this.$bvModal.msgBoxOk('Benutzername oder Passwort falsch', {
            title: 'Login Fehler',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
        }
      }
    },
    showPasswordHandler() {
      this.isShowPassword = !this.isShowPassword;

      if (this.isShowPassword) {
        document.querySelector('#adminLoginFormPasswort').setAttribute('type', 'text');
        return;
      }

      document.querySelector('#adminLoginFormPasswort').setAttribute('type', 'password');
    },
  },
};
</script>